import { Link } from "gatsby";
import React from "react";
import ratioLogoSvg from "../images/ratio-wordmark-v02a.svg";
import MenuModal from "./MenuModal";
import MenuButton from "./MenuButton";
import { closeMenu } from "../utils/menuHelpers";

export default function HeaderDefault() {
  return (
    <div id="global-header" className="header px-medium pt-medium pt-ml-small">
      <div id="global-nav">
        <ul className="grid-fixed-2 grid-align-center">
          <li>
            <Link to="/" onClick={closeMenu}>
              <div className="ratio-logo">
                <img src={ratioLogoSvg} alt="Ratio Lighting Australia" />
              </div>
            </Link>
          </li>
          <li className="">
            <ul className="nav-list-horizontal nav-list-right fs-small fs-ml-large">
              <li data-nosnippet>
                <MenuButton />
              </li>
              <li data-nosnippet>
                <Link
                  to="/filter/"
                  className="nav-list-item"
                  data-nosnippet
                  onClick={closeMenu}
                >
                  Filter
                </Link>
              </li>
              {/* <li data-nosnippet>
              <Link to="/approach/" className="nav-list-item" data-nosnippet>
                Approach
              </Link>
            </li> */}
              <li data-nosnippet>
                <Link
                  to="/info/"
                  className="nav-list-item"
                  data-nosnippet
                  onClick={closeMenu}
                >
                  Info
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        <hr className="mt-small mt-ms-medium mt-ml-small" />
      </div>
      <MenuModal />
    </div>
  );
}
