import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

export default function HelmetDefault({
  children,
  description,
  image,
  location,
  title,
}) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          companyFullName
          companyName
          defaultDescription
          defaultTitle
          instagram
          linkedin
          siteUrl
        }
      }
    }
  `);
  const {
    siteUrl,
    companyFullName,
    companyName,
    defaultDescription,
    defaultTitle,
  } = site.siteMetadata;

  return (
    <Helmet>
      <html lang="en" />

      {title ? (
        <title>
          {title} — {companyName}
        </title>
      ) : (
        <title>
          {companyFullName} — {defaultTitle}
        </title>
      )}

      {/* favicon */}
      {/* <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="alternate icon" href="/favicon.ico" /> */}
      {/* Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
      <meta name="description" content={description || defaultDescription} />
      {/* Open Graph */}
      {/* TODO this is not work and is not hooked in https://github.com/gatsbyjs/gatsby/issues/8787 */}
      {location && <meta property="og:url" content={location.href} />}
      {location && <link rel="canonical" href={location.href} />}
      {/* TODO add social logo */}
      <meta property="og:image" content={image || "/logo.svg"} />
      <meta property="og:title" content={title || defaultTitle} key="ogtitle" />
      <meta
        property="og:site_name"
        content={`${companyFullName} — ${defaultTitle}`}
        key="ogsitename"
      />
      <meta
        property="og:description"
        content={description || defaultDescription}
        key="ogdesc"
      />

      {children}
    </Helmet>
  );
}
