import { Link } from "gatsby";
import React, { Component } from "react";

export default class FooterDefault extends Component {
  render() {
    return (
      <div id="global-footer" className="px-medium">
        <hr />
        <div className="py-small py-ms-medium py-mm-medium py-ml-small">
          <div className="grid-fixed-1 fs-small fs-ml-large">
            <ul className="footer-default">
              <li className="copyright">
                <span className="screen-only">© Ratio 2021–2024</span>
                <span className="print-only print-url">
                  <a href="https://ratio.lighting">https://ratio.lighting</a>
                </span>
              </li>
              <li className="primary">
                <ul className="">
                  <li>
                    <Link to="/info">Newsletter</Link>
                  </li>
                  <li>
                    <a
                      href="https://ratio.lighting/brochures/Ratio-Program-2023-Edition-01.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Brochure
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/ratio.lighting"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
