import React from "react";
import {
  disableScrolling,
  enableScrolling,
  positionMenuBelowHeader,
} from "../utils/menuHelpers";

export default function MenuButton() {
  // const openMenu = (e) => {
  //   console.log("Open Menu");
  //   e.preventDefault();
  //   e.stopPropagation();
  //   const modalBlocker = document.getElementById("menuModalBlocker");
  //   const modalContents = document.getElementById("menuModalContents");
  //   if (modalBlocker && modalContents) {
  //     modalBlocker.classList.remove("hidden");
  //     modalContents.classList.remove("hidden");
  //     positionMenuBelowHeader(); // Call the helper function to position the menu
  //     disableScrolling();
  //   }
  // };
  const toggleMenu = (e) => {
    console.log("Open Menu");
    e.preventDefault();
    e.stopPropagation();
    const modalBlocker = document.getElementById("menuModalBlocker");
    const modalContents = document.getElementById("menuModalContents");
    if (modalBlocker && modalContents) {
      const isMenuHidden = modalBlocker.classList.contains("hidden");
      modalBlocker.classList.toggle("hidden");
      modalContents.classList.toggle("hidden");

      if (isMenuHidden) {
        positionMenuBelowHeader(); // Position the menu if it's being opened
        disableScrolling(); // Disable scrolling if menu is opened
      } else {
        enableScrolling(); // Enable scrolling if menu is closed
      }
    }
  };

  return (
    <a
      href="/filter/"
      className="nav-list-item"
      data-nosnippet
      // onClick={openMenu}
      onClick={toggleMenu}
      // onMouseEnter={openMenu}
    >
      Products
    </a>
  );
}
