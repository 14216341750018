export const positionMenuBelowHeader = () => {
  const globalHeader = document.getElementById("global-header");
  const modalContents = document.getElementById("menuModalContents");
  const modalBlocker = document.getElementById("menuModalBlocker");

  if (globalHeader && modalContents) {
    const headerBottom = globalHeader.getBoundingClientRect().bottom;
    modalContents.style.top = `${headerBottom}px`;
    modalBlocker.style.top = `${headerBottom}px`;
  }
};

export const disableScrolling = () => {
  document.body.classList.add("no-scroll");
};

export const enableScrolling = () => {
  document.body.classList.remove("no-scroll");
};

export const closeMenu = (e) => {
  console.log("Close Menu");
  const modalBlocker = document.getElementById("menuModalBlocker");
  const modalContents = document.getElementById("menuModalContents");
  if (modalBlocker && modalContents) {
    modalBlocker.classList.add("hidden");
    modalContents.classList.add("hidden");
    enableScrolling();
  }
};
