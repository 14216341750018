import React from "react";
import FooterDefault from "./FooterDefault";
import HeaderDefault from "./HeaderDefault";
import HelmetDefault from "./HelmetDefault";
import "../styles/index.scss";
import ModalCover from "./ModalCover";
import { closeMenu } from "../utils/menuHelpers";

export default function LayoutDefault({ children }) {
  return (
    <div id="page-wrapper" onClick={closeMenu}>
      <HelmetDefault />
      <HeaderDefault />
      {children}
      <FooterDefault />
      <ModalCover />
      {/* <div className="mq-debug">
        <span className="mxs">mxs</span>
        <span className="ms">ms</span>
        <span className="mm">mm</span>
        <span className="ml">ml</span>
        <span className="mxl">mxl</span>
        <span className="mxxl">mxxl</span>
        <span className="mxxxl">mxxxl</span>
      </div> */}
    </div>
  );
}
