/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
/* eslint-disable no-use-before-define */
const React = require("react");
const { closeMenu } = require("./src/utils/menuHelpers");
const Layout = require("./src/components/LayoutDefault").default;

exports.wrapPageElement = ({ element, props }) => (
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  <Layout {...props}>{element}</Layout>
);

exports.onRouteUpdate = ({ location, prevLocation }) => {
  closeMenu();
};
